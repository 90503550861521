import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/stabilimenti/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll(token, id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/stabilimenti/get_all?token="+token+"&id_cliente="+id).then(response => {
            return response.data;
        });
    },
    async getOne(_id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/stabilimenti/get_one?_id="+_id).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/stabilimenti/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/stabilimenti/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async sendFile(token, id_stabilimento, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/stabilimenti/send_file?token="+token+"&id_stabilimento="+id_stabilimento, params, { timeout: 5000 }).then(response => {
            return response.data;
        });
    },
    async addPunto(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/stabilimenti/add_punto?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deletePunto(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/stabilimenti/delete_punto?token="+token, params).then(response => {
            return response.data;
        });
    },
}