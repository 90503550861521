<template>
  <div class="newSegnalazione" style="height:100%;">
    <v-layout justify-center align-center style="height:100%;">
      <v-flex xs11 md8 lg6>
          <v-toolbar color="secondary">
              <v-toolbar-title class="white--text">
                  Nuova Segnalazione
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn small icon @click="$router.back()" color="white" class="ml-3 mr-1">
                <v-icon small>fas fa-times</v-icon>
              </v-btn>
          </v-toolbar>
          <v-card outlined>
              <v-form v-model="formValid" ref="form_data" lazy-formValidation>
                  <v-container>
                      <v-layout column>
                          <v-text-field
                              autocomplete="off"
                              label="Titolo"
                              v-model="titolo"
                              hide-details="auto"
                              class="mb-4"
                              required
                              :rules="testoRules"
                          ></v-text-field>
                          <v-textarea
                              autocomplete="off"
                              label="Note"
                              v-model="note"
                              outlined
                              auto-grow
                              :class="!isMaster ? 'mb-5' : ''"
                              hide-details="auto"
                              rows="1"
                              class="mb-0"
                          ></v-textarea>
                          <v-container fluid v-if="isMaster">
                            <v-row>
                              <v-col cols="12" sm="4" class="py-0">
                                <v-checkbox label="Informa Utente" dense v-model="informaUtente"></v-checkbox>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-select
                              v-if="ruolo == 'Master' || ruolo == 'Tecnico' || ruolo == 'Partner'"
                              :items="lista_clienti"
                              v-model="cliente"
                              required
                              outlined
                              hide-details="auto"
                              :rules="requiredRules"
                              label="Cliente"
                              class="mb-4"
                              no-data-text="Nessun Cliente"
                              item-text="nominativo1"
                              item-value="_id"
                          ></v-select>
                          <v-select
                              :items="lista_stabilimenti"
                              v-model="stabilimento"
                              required
                              outlined
                              hide-details="auto"
                              :rules="requiredRules"
                              label="Stabilimento"
                              class="mb-4"
                              no-data-text="Nessuno Stabilimento"
                              item-text="indirizzo"
                              item-value="_id"
                          ></v-select>
                          <v-col cols="12" offset="0" sm="6" offset-sm="3" class="py-0">
                            <VueUploadMultipleImage 
                              :showEdit="false"
                              :showDelete="true"
                              :showAdd="true"
                              :disabled="false"
                              dragText="Carica Immagini"
                              browseText="Drag & Drop"
                              dropText="Rilascia Qui"
                              accept="image/gif,image/jpeg,image/png,image/bmp,image/jpg,image/*"
                              class="uploaderFix"
                              @upload-success="imageListChanged"
                              @before-remove="removeImage"
                              @edit-image="imageListChanged"
                              :data-images="immagini"
                            />
                          </v-col>
                          <br>
                          <ListaEvacuatori :lista_evacuatori="array_evacuatori_selected" :id_stabilimento="id_stabilimento" v-if="array_evacuatori_selected && array_evacuatori_selected.length" :actionScheda="false"/>
                          <div id="reader"></div>
                          <v-container grid-list-sm fluid>
                            <v-row>
                              <v-col cols="12" md="4" sm="12" class="pt-0"> 
                                <v-btn :disabled="!stabilimento" color="primary" width="100%" @click="scanQRcode">Scan QR</v-btn> 
                              </v-col>
                              <v-col cols="12" md="4" sm="12" class="pt-0"> 
                                <v-btn :disabled="!stabilimento" color="primary" width="100%" @click="isChosingEvacuatore = !isChosingEvacuatore">Scegli dalla lista</v-btn> 
                              </v-col>
                              <v-col cols="12" md="4" sm="12" class="pt-0"> 
                                <v-btn :disabled="!stabilimento || (stabilimento && !stabilimento.file)" color="primary" width="100%" @click="isChosingEvacuatoreMappa = !isChosingEvacuatoreMappa">Scegli dalla mappa</v-btn> 
                              </v-col>
                            </v-row>
                          </v-container>
                      </v-layout>
                  </v-container>
              </v-form>
              <v-card-actions>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0">
                      <v-btn color="primary" width="100%" @click="submit" :disabled="!formValid || loading">Aggiungi</v-btn> 
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
          </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="isChosingEvacuatore"
      width="auto"
      max-width="600"
    >
      <ListaEvacuatori :id_stabilimento="id_stabilimento" :list_selected="array_evacuatori_selected" :isChosing="true" :multipleChosing="true" @evacuatoriSelected="evacuatoriSelected" v-if="stabilimento && isChosingEvacuatore" :actionScheda="false"/>
    </v-dialog>
    <v-dialog 
      v-model="isChosingEvacuatoreMappa"
      max-width="90vw"
    >
      <MappaEvacuatori :id_stabilimento="id_stabilimento" :list_selected="array_evacuatori_selected" :isChosing="true" :multipleChosing="true" @evacuatoriSelected="evacuatoriSelectedMappa" v-if="stabilimento && isChosingEvacuatoreMappa"/>
    </v-dialog>
  </div>
</template>
<script>
import apiUtenti from '@/js/pages/utenti';
import apiStabilimenti from '@/js/pages/stabilimenti';
import apiSegnalazioni from '@/js/pages/segnalazioni';
import apiEvacuatori from '@/js/pages/evacuatori';
import { testoRules, requiredRules } from '@/js/validationRules';
import VueUploadMultipleImage from 'vue-upload-multiple-image';
const ListaEvacuatori = () => import('@/components/Lista_evacuatori');
const MappaEvacuatori = () => import('@/components/Mappa_evacuatori');

export default {
  name: 'newSegnalazione',
  components: {
    ListaEvacuatori,
    MappaEvacuatori,
    VueUploadMultipleImage,
  },
  data() {
    return {
      token: this.$cookies.get('token'),
      ruolo: this.$store.state.ruolo,
      titolo: '',
      note: '',
      informaUtente: false,
      cliente: undefined,
      id_stabilimento: undefined,
      stabilimento: undefined,
      lista_clienti: [],
      lista_stabilimenti: [],
      formValid: false,
      testoRules: testoRules,
      requiredRules: requiredRules,
      loading: false,
      lista_tipi_segnalazioni: ['Manutenzione', 'Posa', 'Controllo', 'Altro'],
      isChosingEvacuatore: false,
      array_evacuatori_selected: [],
      isMaster: false,
      isChosingEvacuatoreMappa: false,
      imageUploaded: undefined,
      immagini: [],
    }
  },
  watch: {
    async cliente() {
      this.lista_stabilimenti = await apiStabilimenti.getAll(this.token, this.cliente);
      if(
        this.lista_stabilimenti &&
        this.lista_stabilimenti.length === 1
      ) this.stabilimento = this.lista_stabilimenti[0];
      else this.stabilimento = undefined;
    },
    stabilimento(){
      if(
        this.stabilimento &&
        this.stabilimento['_id']
      ) this.id_stabilimento = this.stabilimento['_id'];
      else this.id_stabilimento = this.stabilimento || undefined;
    },
  },
  async created() {
    if(this.ruolo == 'Cliente'){
      this.isMaster = false;
      let utente = await apiUtenti.getUtenteFromToken(this.token);
      this.cliente = utente['_id'];
    } else if(this.ruolo == 'Partner') {
      this.isMaster = true;
      this.lista_clienti = await apiUtenti.getAllClientPartner(this.token, 'Baggi');
    } else {
      this.isMaster = true;
      this.lista_clienti = await apiUtenti.getAll(this.token, 'Cliente');
    }
  },
  methods: {
    async scanQRcode() {
      //https://github.com/mebjas/html5-qrcode
      Html5Qrcode.getCameras().then(devices => {
        if (devices && devices.length) {
          const html5QrCode = new Html5Qrcode("reader");
          html5QrCode.start(
            { facingMode: 'environment' }, 
            {
              fps: 10, 
              qrbox: 250 
            },
            async (qrCodeMessage) => {
              if(qrCodeMessage) {
                const urlParams = new URLSearchParams(new URL(qrCodeMessage).searchParams);
                const qrFound = urlParams.get('qr');
                let id_evacuatore = await apiEvacuatori.getOneFromMatricola(this.token, qrFound);
                if(this.array_evacuatori_selected.indexOf(id_evacuatore) === -1) this.array_evacuatori_selected.push(id_evacuatore);
                setTimeout(() => {
                  html5QrCode.stop();
                  document.getElementById('reader').innerHTML = '';
                }, 100);
              }
            })
          .catch(err => {
              alert('big_errore -> ',err);
          });

        }
      }).catch(err => {
        console.log('error cameras',err);
      });
    },
    async submit(){
      if(
        this.$refs.form_data.validate() &&
        this.formValid &&
        !this.loading
      ) {
        this.loading = true;
        let data = {
          titolo: this.titolo,
          informa_utente: this.informaUtente,
          note: this.note,
          da_cliente: this.ruolo == 'Cliente' ? true : false,
          id_cliente: this.cliente,
          id_stabilimento: this.id_stabilimento,
          id_evacuatori: this.array_evacuatori_selected,
        };
        let id_segnalazione = await apiSegnalazioni.insertNew(this.token, data);
        if(id_segnalazione) {
          let formData = new FormData();
          for (const immagine of this.immagini) {
            let finalBlob = await fetch(immagine.path).then(res => res.blob());
            formData.append("immagini", finalBlob, immagine.name);
          }
          apiSegnalazioni.sendFileImmagini(this.token, id_segnalazione, formData);
        }
        this.$router.back();
        this.loading = false;
      }
    },
    async evacuatoriSelected(evacuatori) {
      this.array_evacuatori_selected = [];
      let those = this;
      setTimeout(() => {
        if(
          evacuatori &&
          evacuatori.length
        ) those.array_evacuatori_selected = evacuatori;
        else those.array_evacuatori_selected = [];
        those.isChosingEvacuatore = false;
      }, 10);
    },
    async evacuatoriSelectedMappa(evacuatori) {
      this.array_evacuatori_selected = [];
      let those = this;
      setTimeout(() => {
        if(
          evacuatori &&
          evacuatori.length
        ) those.array_evacuatori_selected = evacuatori;
        else those.array_evacuatori_selected = [];
        those.isChosingEvacuatoreMappa = false;
      }, 10);
    },
    async inviaImmagine() {
      if (this.imageUploaded) {
        let formData = new FormData();
        formData.append("immagine", this.imageUploaded);
        await apiProdotti.uploadImage(this.token, formData, this.id);
        await this.loadData();
        this.imageUploaded = undefined;
      }
    },
    imageListChanged(formData, index, fileList) {
      this.immagini = fileList;
    },
    async removeImage(index, done, fileList) {
      var r = confirm("Rimuovere l'immagine?");
      if (r == true) {
        await done();
        this.immagini = fileList;
      }
    },
  },
}
</script>
