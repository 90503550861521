import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/evacuatori/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll(token, id_stabilimento, semestri, reparto) {
        if(!semestri) semestri = '';
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/evacuatori/get_all?token="+token+"&id_stabilimento="+id_stabilimento+"&semestri="+semestri+'&reparto='+reparto).then(response => {
            return response.data;
        });
    },
    async getAllFromList(token, elenco, semestri) {
        if(!semestri) semestri = '';
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/evacuatori/get_all_from_list?token="+token+"&elenco_id="+JSON.stringify(elenco)+"&semestri="+semestri).then(response => {
            return response.data;
        });
    },
    async getAllMatricole(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/evacuatori/get_all_matricole?token="+token).then(response => {
            return response.data;
        });
    },
    async getStabilimento(_id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/stabilimenti/get_one?_id="+_id).then(response => {
            return response.data;
        });
    },
    async getOne(token, _id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/evacuatori/get_one?token="+token+"&_id="+_id).then(response => {
            return response.data;
        });
    },
    async getOneFromPoint(token, _id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/evacuatori/get_one_from_point?token="+token+"&_id="+_id).then(response => {
            return response.data;
        });
    },
    async getOneFromMatricola(token, matricola) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/evacuatori/get_one_from_matricola?token="+token+"&matricola="+matricola).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/evacuatori/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/evacuatori/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async connectPunto(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/evacuatori/connect_punto?token="+token, params).then(response => {
            return response.data;
        });
    },
    async downloadElenco(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/evacuatori/download_lista?token="+token, params).then(response => {
            return response.data;
        });
    },
}