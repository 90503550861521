import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/segnalazioni/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/segnalazioni/get_all?token="+token).then(response => {
            return response.data;
        });
    },
    async getAllPartner(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/segnalazioni/get_all_partner?token="+token).then(response => {
            return response.data;
        });
    },
    async getAllMine(token) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/segnalazioni/get_all_mine?token="+token).then(response => {
            return response.data;
        });
    },
    async getOne(_id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/segnalazioni/get_one?_id="+_id).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/segnalazioni/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/segnalazioni/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async sendFileImmagini(token, id_segnalazione, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/segnalazioni/send_file_immagini?token="+token+"&id_segnalazione="+id_segnalazione, params).then(response => {
            return response.data;
        });
    },
}